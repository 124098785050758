import winston from 'winston';
import env from './env';

export default class ServerLogger {
  static FORMAT =
    process.env.NODE_ENV === 'production'
      ? winston.format.combine(
          winston.format.timestamp(),
          winston.format.errors({ stack: true }),
          winston.format.json()
        )
      : winston.format.simple();

  static logger = winston.createLogger({
    level: env.NEXT_PUBLIC_DEBUG_FLAG ? 'debug' : 'info',
    format: this.FORMAT,
    defaultMeta: {
      service: 'onereal-api',
    },
    transports: [new winston.transports.Console({})],
  });

  static log(...messages: any[]): void {
    this.sendLog('info', ...messages);
  }

  static debug(...messages: any[]): void {
    this.sendLog('debug', ...messages);
  }

  static warn(...messages: any[]): void {
    this.sendLog('warn', ...messages);
  }

  static error(...messages: any[]): void {
    this.sendLog('error', ...messages);
  }

  static sendLog(level: string, ...objects: any[]): void {
    const message = objects
      .map((obj) => {
        if (obj instanceof Error) {
          return obj.stack;
        }

        return obj;
      })
      .join(' ');

    this.logger.log(level, message);
  }
}
