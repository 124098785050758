// eslint-disable-next-line import/no-nodejs-modules
import { ParsedUrlQuery } from 'querystring';
// eslint-disable-next-line import/no-nodejs-modules
import { UrlObject } from 'url';
import { isEmpty, isNil, isNumber, omit, omitBy, pick, uniq } from 'lodash';
import Image from 'next/image';
import router from 'next/router';
import { CheckboxInputProps } from '../components/CheckboxInput';
import { DropdownItem } from '../components/Dropdown';
import {
  AbbreviatedListing,
  AbbreviatedListingListingTypeEnum,
  Address,
  GetListingsByLatLonRequest,
  GetListingsByLatLonRequestSortBasisEnum,
  GetListingsByLatLonRequestSortOrderEnum,
  GetListingsResponse,
  GetRealListingsWithFilterRequest,
  LegaleseElement,
  ListingDetailResponse,
  PropertyFiltersBuyRentEnum,
  PropertyFiltersPropertyTypesEnum,
  WandererControllerApi,
} from '../openapi/wanderer';
import ErrorService from '../services/ErrorService';
import {
  Agent,
  DisclaimerType,
  IProperty,
  PropertiesFilterSortType,
  PropertiesFilterType,
  PropertiesResponse,
  PropertiesSearchRequest,
  PropertyType,
  RealApiAgentWebsiteResponse,
} from '../types/real-api-types';
import {
  FilterAmenitiesEnum,
  FilterListingTypeEnum,
  FilterPropertyTypesEnum,
  RealPropertiesResponse,
  RealPropertyDetails,
  RealPropertyDetailsListingTypeEnum,
  PropertySearchControllerApi,
  SearchPropertiesLocationRequest,
} from '../openapi/artemis';
import env from './env';
import ServerLogger from './ServerLogger';
import {
  getArtemisConfiguration,
  getWandererConfiguration,
} from './OpenapiConfigurationUtils';
import {
  isCASiteOnly,
  isMultiRegionSite,
  isUSSiteOnly,
} from './SiteConfiguration';
import { capitalizeEnum } from './StringUtils';
import { displayAmount, MoneyValueCurrencyEnum } from './CurrencyUtils';

export const DEFAULT_PROPERTIES_ITEM_SIZE = 12;

export const DEFAULT_RADIUS = 5;

export const DEFAULT_CANADA_RADIUS_IN_KM = 16;

export const DEFAULT_MAX_PRICE_RANGE = 3750000;

export const DEFAULT_MIN_PRICE_RANGE = 0;

export const DEFAULT_RANGE_STEPS = 5000;

export const MAX_PAGES_TO_SHOW = 10000;

export const FILTER_KEYS_TO_IGNORE_COUNT: Array<keyof PropertiesFilterType> = [
  'page_number',
  'type',
  'google_place_id',
  'search_label',
  'is_active',
  'is_rental',
  'sort_by',
  'longitude',
  'latitude',
  'radius',
  'wanderer',
];

export const FILTER_KEYS_TO_CLEAR: Array<keyof PropertiesFilterType> = [
  'beds_min',
  'baths_min',
  'is_house',
  'is_townhouse',
  'is_condo',
  'is_multifamily',
  'is_land',
  'is_price_reduction',
  'is_foreclosure',
  'is_new_construction',
  'has_back_yard',
  'has_fireplace',
  'has_golf',
  'has_patio_deck',
  'has_spa_hot_tub',
  'has_porch',
  'has_pool_private',
  'has_pool_area',
  'has_photos',
  'price_min',
  'price_max',
  'sort_by',
  'is_vacant_land',
  'is_multi_family',
  'is_single_family',
  'is_retail',
  'is_office',
  'is_hospitality',
  'is_business',
  'is_agriculture',
  'is_parking',
  'is_recreational',
  'is_industrial',
  'has_garage',
  'has_basement',
  'has_air_conditioning',
  'has_waterfront',
  'has_washer_dryer_hookup',
  'has_pets_allowed',
  'has_tennis_court',
];

export const MinBedsBathsMap = {
  ANY: 0,
  ONE_OR_MORE: 1,
  TWO_OR_MORE: 2,
  THREE_OR_MORE: 3,
  FOUR_OR_MORE: 4,
  FIVE_OR_MORE: 5,
};

export const FILTER_KEYS = uniq([
  ...FILTER_KEYS_TO_IGNORE_COUNT,
  ...FILTER_KEYS_TO_CLEAR,
]);

export const getPropertySearchReq = (
  filter: PropertiesFilterType,
  defaultSearchReq: Pick<PropertiesSearchRequest, 'slug'> = {},
  limit = DEFAULT_PROPERTIES_ITEM_SIZE
): PropertiesSearchRequest => ({
  filter: {
    is_rental: filter.type === 'rent',
    is_active: true,
    ...filter,
  },
  page_number: filter.page_number!,
  limit,
  ...defaultSearchReq,
});

export const getPropertiesFilterFromAgentWebsiteResponse = async (
  agentWebsiteResponse: RealApiAgentWebsiteResponse,
  filter?: Partial<PropertiesFilterType>
): Promise<PropertiesFilterType> => {
  const geoCoder = new google.maps.Geocoder();

  if (!agentWebsiteResponse?.website?.filter?.google_place_id) {
    let googlePlaceResults: google.maps.GeocoderResult[] = [];
    const zip = agentWebsiteResponse?.agent?.zipcodes?.split(',')[0];

    try {
      const { results } = await geoCoder.geocode({
        address: zip,
        componentRestrictions: {
          country: 'us',
        },
      });

      googlePlaceResults = results;
    } catch (e) {
      ServerLogger.debug('Unable to find locations for that zip', e);
      ErrorService.notify('Unable to find locations for that zip', e, {
        agent: { zip },
      });
    }

    if (googlePlaceResults.length > 0) {
      return getInitialFilter(googlePlaceResults[0], filter);
    }
  }

  return getOnlyAppliedFilters({
    ...agentWebsiteResponse?.website?.filter,
    page_number: 0,
  } as unknown as PropertiesFilterType);
};

export const getInitialFilter = (
  place: google.maps.places.PlaceResult,
  filter?: Partial<Pick<PropertiesFilterType, 'type' | 'has_photos'>>
): PropertiesFilterType => {
  const updatedFilter: PropertiesFilterType = {
    page_number: 0,
    search_label: place?.formatted_address,
    google_place_id: place?.place_id,
    type: filter?.type || 'buy',
    latitude: `${place?.geometry?.location?.lat()}`,
    longitude: `${place?.geometry?.location?.lng()}`,
  };

  return updatedFilter;
};

export const getOnlyAppliedFilters = (
  filter: PropertiesFilterType
): PropertiesFilterType =>
  pick(
    omitBy(filter, (f) => typeof f === 'undefined' || f === false),
    FILTER_KEYS
  ) as PropertiesFilterType;

export const resetFilter = (
  filter: PropertiesFilterType,
  fieldsToRemove: keyof PropertiesFilterType | Array<keyof PropertiesFilterType>
): PropertiesFilterType => {
  return omit(filter, fieldsToRemove) as PropertiesFilterType;
};

export const getFilteredProperties = async (
  filter: PropertiesFilterType,
  defaultFilter: Pick<PropertiesSearchRequest, 'slug'> = {},
  limit = DEFAULT_PROPERTIES_ITEM_SIZE
): Promise<PropertiesResponse | void> => {
  if (isEmpty(filter)) {
    return {
      disclaimers: [],
      filter: {},
      properties: [],
    } as unknown as PropertiesResponse;
  }

  let listingsRequest: GetListingsByLatLonRequest;
  let canadalistingRequest: SearchPropertiesLocationRequest;
  try {
    const showUSListings = isUSSiteOnly() || isMultiRegionSite();
    listingsRequest = {
      ...getWandererFilterRequest(filter, defaultFilter),
      pageSize: limit,
    };
    if (showUSListings) {
      const { data } = await new WandererControllerApi(
        getWandererConfiguration()
      ).getListingsByLatLon(listingsRequest);

      return getPropertiesResponseFromWandererListings(data, filter);
    } else {
      canadalistingRequest = {
        ...getArtemisFilterRequest(filter),
        pageSize: limit,
      };
      const { data: newData } = await new PropertySearchControllerApi(
        getArtemisConfiguration()
      ).searchPropertiesByLocationCollection(canadalistingRequest);

      return getPropertiesResponseFromArtemisListings(newData, filter);
    }
  } catch (e) {
    ErrorService.notify('Unable to fetch properties', e, {
      filter: { ...filter },
      defaultFilter: { ...defaultFilter },
      listingsRequest: { ...(listingsRequest || {}) },
      limit: { limit: DEFAULT_PROPERTIES_ITEM_SIZE },
    });
  }
};

export const getFilteredPropertiesFromRequest = async (
  filterParams: GetRealListingsWithFilterRequest
): Promise<IProperty[]> => {
  try {
    const { data } = await new WandererControllerApi(
      getWandererConfiguration()
    ).getRealListingsWithFiltering(filterParams);
    return data.listings?.map((listing) =>
      getIPropertyFromAbbreviatedListing(listing, data.legalese!)
    );
  } catch (e) {
    ErrorService.notifyIgnoreNotFound(
      'Unable to fetch property details from wanderer',
      e,
      {
        minPrice: { minPrice: filterParams.propertyFilters.minPrice },
        propertyTypes: {
          propertyTypes: filterParams.propertyFilters.propertyTypes[0],
        },
      }
    );

    return;
  }
};

export const getWandererFilterRequest = (
  filter: PropertiesFilterType,
  defaultFilter: Pick<PropertiesSearchRequest, 'slug'> = {},
  limit = DEFAULT_PROPERTIES_ITEM_SIZE
): GetListingsByLatLonRequest => {
  const propertyTypes: PropertyFiltersPropertyTypesEnum[] = [];

  if (filter.is_condo)
    propertyTypes.push(PropertyFiltersPropertyTypesEnum.Condo);
  if (filter.is_house)
    propertyTypes.push(PropertyFiltersPropertyTypesEnum.House);
  if (filter.is_land) propertyTypes.push(PropertyFiltersPropertyTypesEnum.Land);
  if (filter.is_multifamily)
    propertyTypes.push(PropertyFiltersPropertyTypesEnum.Multifamily);
  if (filter.is_townhouse)
    propertyTypes.push(PropertyFiltersPropertyTypesEnum.Townhouse);

  return {
    propertyFilters: {
      buyRent:
        filter.type === 'buy'
          ? PropertyFiltersBuyRentEnum.Buy
          : PropertyFiltersBuyRentEnum.Rent,
      flagHasPhotos: filter.has_photos,
      flagNewConstruction: filter.is_new_construction,
      maxPrice: getNumberValue(filter.price_max),
      minPrice: getNumberValue(filter.price_min) || 0,
      minBaths: MinBedsBathsMap[filter.baths_min],
      minBedrooms: MinBedsBathsMap[filter.beds_min],
      propertyTypes: propertyTypes.length > 0 ? propertyTypes : undefined,
    },
    agentId: defaultFilter.slug,
    latitude: filter.latitude as unknown as number,
    longitude: filter.longitude as unknown as number,
    pageNumber: filter.page_number! + 1,
    pageSize: limit,
    radius: filter.radius || DEFAULT_RADIUS,
    ...translateWandererSortBasis(filter.sort_by!),
  };
};

export const getArtemisFilterRequest = (
  filter: PropertiesFilterType,
  limit = DEFAULT_PROPERTIES_ITEM_SIZE
): SearchPropertiesLocationRequest => {
  const propertyTypes: FilterPropertyTypesEnum[] = [];
  if (filter.is_vacant_land) {
    propertyTypes.push(FilterPropertyTypesEnum.VacantLand);
  }
  if (filter.is_multi_family) {
    propertyTypes.push(FilterPropertyTypesEnum.MultiFamily);
  }
  if (filter.is_single_family) {
    propertyTypes.push(FilterPropertyTypesEnum.SingleFamily);
  }
  if (filter.is_condo) {
    propertyTypes.push(FilterPropertyTypesEnum.Condo);
  }
  if (filter.is_retail) {
    propertyTypes.push(FilterPropertyTypesEnum.Retail);
  }
  if (filter.is_office) {
    propertyTypes.push(FilterPropertyTypesEnum.Office);
  }
  if (filter.is_hospitality) {
    propertyTypes.push(FilterPropertyTypesEnum.Hospitality);
  }
  if (filter.is_business) {
    propertyTypes.push(FilterPropertyTypesEnum.Business);
  }
  if (filter.is_agriculture) {
    propertyTypes.push(FilterPropertyTypesEnum.Agriculture);
  }
  if (filter.is_parking) {
    propertyTypes.push(FilterPropertyTypesEnum.Parking);
  }
  if (filter.is_recreational) {
    propertyTypes.push(FilterPropertyTypesEnum.Recreational);
  }
  if (filter.is_industrial) {
    propertyTypes.push(FilterPropertyTypesEnum.Industrial);
  }

  const amenities: FilterAmenitiesEnum[] = [];
  if (filter.has_pool_area) {
    amenities.push(FilterAmenitiesEnum.Pool);
  }
  if (filter.has_spa_hot_tub) {
    amenities.push(FilterAmenitiesEnum.HotTub);
  }
  if (filter.has_garage) {
    amenities.push(FilterAmenitiesEnum.Garage);
  }
  if (filter.has_basement) {
    amenities.push(FilterAmenitiesEnum.Basement);
  }
  if (filter.has_fireplace) {
    amenities.push(FilterAmenitiesEnum.Fireplace);
  }
  if (filter.has_air_conditioning) {
    amenities.push(FilterAmenitiesEnum.AirConditioning);
  }
  if (filter.has_waterfront) {
    amenities.push(FilterAmenitiesEnum.Waterfront);
  }
  if (filter.has_washer_dryer_hookup) {
    amenities.push(FilterAmenitiesEnum.WasherDryerHookup);
  }
  if (filter.has_pets_allowed) {
    amenities.push(FilterAmenitiesEnum.PetsAllowed);
  }
  if (filter.has_back_yard) {
    amenities.push(FilterAmenitiesEnum.Backyard);
  }
  if (filter.has_golf) {
    amenities.push(FilterAmenitiesEnum.Golf);
  }
  if (filter.has_porch) {
    amenities.push(FilterAmenitiesEnum.Porch);
  }
  if (filter.has_patio_deck) {
    amenities.push(FilterAmenitiesEnum.PatioDeck);
  }
  if (filter.has_tennis_court) {
    amenities.push(FilterAmenitiesEnum.TennisCourt);
  }

  return {
    latLong: {
      lat: filter.latitude as unknown as number,
      lon: filter.longitude as unknown as number,
    },
    searchDistanceUnit: 'KILOMETERS',
    distance: filter.radius || DEFAULT_CANADA_RADIUS_IN_KM,
    filter: {
      listingType:
        filter.type === 'buy'
          ? FilterListingTypeEnum.Buy
          : FilterListingTypeEnum.Rent,
      minPrice: getNumberValue(filter.price_min) || 0,
      maxPrice: getNumberValue(filter.price_max),
      minBeds: MinBedsBathsMap[filter.beds_min],
      minBaths: MinBedsBathsMap[filter.baths_min],
      amenities: amenities,
      propertyTypes: propertyTypes,
    },
    pageNumber: filter.page_number!,
    pageSize: limit,
  };
};

export const translateWandererSortBasis = (
  type: PropertiesFilterSortType
): {
  sortBasis: GetListingsByLatLonRequestSortBasisEnum;
  sortOrder: GetListingsByLatLonRequestSortOrderEnum;
} => {
  if (type === 'cheapest') {
    return {
      sortBasis: GetListingsByLatLonRequestSortBasisEnum.Price,
      sortOrder: GetListingsByLatLonRequestSortOrderEnum.Ascending,
    };
  }

  if (type === 'most_expensive') {
    return {
      sortBasis: GetListingsByLatLonRequestSortBasisEnum.Price,
      sortOrder: GetListingsByLatLonRequestSortOrderEnum.Decending,
    };
  }

  if (type === 'distance') {
    return {
      sortBasis: GetListingsByLatLonRequestSortBasisEnum.Distance,
      sortOrder: GetListingsByLatLonRequestSortOrderEnum.Ascending,
    };
  }

  if (type === 'newest') {
    return {
      sortBasis: GetListingsByLatLonRequestSortBasisEnum.ListingDate,
      sortOrder: GetListingsByLatLonRequestSortOrderEnum.Ascending,
    };
  }

  return {
    sortBasis: GetListingsByLatLonRequestSortBasisEnum.Distance,
    sortOrder: GetListingsByLatLonRequestSortOrderEnum.Ascending,
  };
};

export const getNumberValue = (
  val: string | string[] | undefined
): number | undefined =>
  typeof val !== 'undefined' ? parseInt(val as string, 10) : undefined;

export const getPropertiesResponseFromWandererListings = (
  data: GetListingsResponse,
  filter: PropertiesFilterType
): PropertiesResponse => ({
  legalese: data.legalese,
  disclaimers: data.legalese?.map((disclaimer) => ({
    image_url: disclaimer.asset?.url || null,
    body: disclaimer.text,
  })) as unknown as DisclaimerType[],
  filter,
  has_more_pages: data.hasNextPage!,
  latitude: filter.latitude as unknown as number,
  longitude: filter.longitude as unknown as number,
  page: filter.page_number!,
  properties: data.listings?.map((listing, index) => ({
    address1: listing.address?.deliveryLine,
    address2: `${listing.address?.city}, ${listing.address?.state}, ${listing.address?.zip}`,
    agent_website_url: '',
    baths: listing.baths?.total || null,
    beds: listing.beds || null,
    city: listing.address?.city,
    county: 'US',
    cover_image: 0,
    distressed: null,
    dom: index,
    gps_2: [listing.coordinates?.latitude, listing.coordinates?.longitude],
    is_rental: listing.listingType === AbbreviatedListingListingTypeEnum.Rental,
    latitude: listing.coordinates?.latitude,
    list_price: listing.listPrice,
    listing_office_name: '',
    longitude: listing.coordinates?.longitude,
    mls: listing.market,
    mls_id: '',
    mlsnum: listing.id,
    new_construction: '',
    photos:
      listing.images?.map((image, index) => ({
        index,
        large: image,
        small: image,
        medium: image,
        thumbnail: image,
      })) || [],
    photos_count: listing.images?.length || 0,
    price_short: listing.listPrice,
    property_type: listing.propertyType,
    remarks: '',
    sq_ft: null,
    state: listing.address?.state,
    status: 'Active',
    street_name: '',
    style: '',
    subdivision: '',
    zip: listing.address?.zip,
    legalese: listing?.legalese || [],
    attributionContact: listing?.attributionContact,
  })),
  total_properties: data.hasNextPage
    ? filter.page_number! * data.pageSize! + data.pageSize!
    : filter.page_number! * data.pageSize!,
});

export const getPropertiesResponseFromArtemisListings = (
  data: RealPropertiesResponse,
  filter: PropertiesFilterType
): PropertiesResponse => ({
  legalese: [],
  disclaimers: [],
  filter,
  has_more_pages: data.hasNextPage!,
  latitude: filter.latitude as unknown as number,
  longitude: filter.longitude as unknown as number,
  page: data.pageNumber!,
  properties: data.realPropertyDetailsList?.map((listing, index) => ({
    address1: listing?.address?.streetAddress
      ? listing?.address?.streetAddress
      : `${
          listing?.address?.unitNumber ? `${listing?.address?.unitNumber} ` : ''
        }${
          listing?.address?.streetNumber
            ? `${listing?.address?.streetNumber}`
            : ''
        }${
          listing?.address?.streetName ? ` ${listing?.address?.streetName}` : ''
        }${
          listing?.address?.streetSuffix
            ? ` ${listing?.address?.streetSuffix}`
            : ''
        }`,
    address2: `${listing?.address?.city}, ${listing?.address?.province}, ${listing?.address?.postalCode}`,
    agent_website_url: '',
    baths: !!listing?.building?.bathrooms?.bathroomsTotal
      ? listing?.building?.bathrooms?.bathroomsTotal
      : null,
    beds: listing?.building?.bedrooms?.bedroomsTotal || null,
    city: listing?.address?.city,
    county: 'CA',
    cover_image: 0,
    distressed: null,
    dom: index,
    gps_2: [listing?.location?.lat, listing?.location?.lon],
    is_rental: listing?.listingType === RealPropertyDetailsListingTypeEnum.Rent,
    latitude: listing?.location?.lat,
    list_price: listing?.listingInformation?.price,
    listing_office_name: '',
    longitude: listing?.location?.lon,
    mls: '',
    mls_id: '',
    mlsnum: listing?.id,
    new_construction: '',
    photos:
      listing?.propertyPhotos?.map((image, index) => ({
        index,
        large: image.realResponsiveImages?.realImages?.[0].url,
        small: image.realResponsiveImages?.realImages?.[0].url,
        medium: image.realResponsiveImages?.realImages?.[0].url,
        thumbnail: image.realResponsiveImages?.realImages?.[0].url,
      })) || [],
    photos_count: listing?.propertyPhotos?.length || 0,
    price_short: listing?.listingInformation?.price,
    property_type:
      capitalizeEnum(listing?.realCharacteristics?.propertyType) || null,
    remarks: '',
    sq_ft: null,
    state: listing?.address?.province,
    status: 'Active',
    street_name: '',
    style: '',
    subdivision: '',
    zip: listing?.address?.postalCode,
    legalese: [],
    attributionContact: undefined,
  })),
  total_properties: data?.totalCount > data?.pageSize ? data?.totalCount : 12,
});

export const getPropertyDetailsFromArtemisListings = (
  listing: RealPropertyDetails
): ListingDetailResponse => ({
  fullListing: {
    address: {
      deliveryLine: listing?.address?.streetAddress
        ? listing?.address?.streetAddress
        : `${
            listing?.address?.unitNumber
              ? `${listing?.address?.unitNumber} `
              : ''
          }${
            listing?.address?.streetNumber
              ? `${listing?.address?.streetNumber}`
              : ''
          }${
            listing?.address?.streetName
              ? ` ${listing?.address?.streetName}`
              : ''
          }${
            listing?.address?.streetSuffix
              ? ` ${listing?.address?.streetSuffix}`
              : ''
          }`,
      city: listing?.address?.city,
      state: listing?.address?.province,
      zip: listing?.address?.postalCode,
    },
    area: listing?.land?.sizeTotalText || null,
    baths: {
      full: listing?.building?.bathrooms?.bathroomsTotal || null,
      half: listing?.building?.bathrooms?.bathroomsPartial || null,
      total: listing?.building?.bathrooms?.bathroomsTotal || null,
    },
    beds: listing?.building?.bedrooms?.bedroomsTotal || null,
    buildingName: null,
    coListingAgent: null,
    coListingOffice: null,
    coordinates: {
      latitude: listing?.location?.lat,
      longitude: listing?.location?.lon,
    },
    county: 'CA',
    daysOnHJI: null,
    description: listing?.listingInformation?.publicRemarks || null,
    distressed: null,
    floors: listing?.building?.storiesTotal?.toString() || null,
    fullListingAddress: null,
    geoType: 'listing',
    id: listing?.sourceId,
    images:
      listing?.propertyPhotos?.map(
        (image) => image.realResponsiveImages?.realImages?.[0].url || ''
      ) || [],
    lastUpdated: listing?.lastUpdated || null,
    leaseAvailability: null,
    leaseTerm: listing?.listingInformation?.leasePerTime || null,
    listingAgent: {
      email: null,
      id: listing?.agentList?.[0]?.id || null,
      licenseNumber: null,
      name: listing?.agentList?.[0]?.name || null,
      phone: listing?.agentList?.[0]?.phones?.[0]?.phone || null,
    },
    listingDate: listing?.listingInformation?.listingContractDate || null,
    listingOffice: {
      id: listing?.realOfficeDetails?.[0]?.id || null,
      name: listing?.realOfficeDetails?.[0]?.name || null,
      phone: listing?.realOfficeDetails?.[0]?.phones?.[0]?.phone || null,
    },
    listingType: listing?.listingType || null,
    listPrice: listing?.listingInformation?.price || null,
    lotSize: {
      acres: listing?.land?.lotSizeArea || null,
      sqft: null,
      depth: +listing?.land?.sizeDepth || null,
      frontage: +listing?.land?.sizeFrontage || null,
    },
    modifiedDate: null,
    newConstruction: listing?.building?.age === 0,
    pendingDate: null,
    propertyType:
      capitalizeEnum(listing?.realCharacteristics?.propertyType) || null,
    schools: null,
    size: +listing?.livingArea?.split(';')?.[0] || null,
    status: 'Active',
    style: listing?.building?.architecturalStyle?.toString() || null,
    subdivision: listing?.address?.subdivision || null,
    tourURL: listing?.listingInformation?.moreInformationLink || null,
    units: null,
    yearBuilt: new Date().getFullYear() - listing?.building?.age || null,
    attributionContact: null,
    market: '',
    mlsId: listing?.listingId,
    features: {
      amenities: listing?.building?.amenities || [],
      appliancesAndEquipment: listing?.building?.appliances || [],
      bedrooms: listing?.building?.bedrooms?.bedroomsTotal
        ? [`Total Bedrooms: ${listing?.building?.bedrooms?.bedroomsTotal}`]
        : null, // Shown in the UI
      bathrooms:
        listing?.building?.bathrooms?.bathroomsTotal &&
        listing?.building?.bathrooms?.bathroomsPartial
          ? [
              `Total Bathrooms: ${listing?.building?.bathrooms?.bathroomsTotal}`,
              `Partial Bathrooms: ${listing?.building?.bathrooms?.bathroomsPartial}`,
            ]
          : listing?.building?.bathrooms?.bathroomsTotal
          ? [`Total Bathrooms: ${listing?.building?.bathrooms?.bathroomsTotal}`]
          : null, // Shown in the UI
      cooling: !!listing?.building?.coolingType?.length
        ? [`Cooling Details: ${listing?.building?.coolingType?.toString()}`]
        : null, // Shown in the UI
      disabilityFeatures: null,
      disclosures: null,
      exteriorFeatures: listing?.building?.exteriorFinish || [],
      fence: listing?.land?.fenceType || [],
      fireplaces: listing?.building?.fireplaceType || [],
      foundation: listing?.building?.foundationType || [],
      garage: !!listing?.parkingDetails?.parkingSpaces?.length
        ? [
            `Parking Spaces: ${listing?.parkingDetails?.parkingSpaces
              ?.map((ele) => ele.spaces)
              ?.toString()}`,
          ]
        : null, // Shown in the UI
      heating: !!listing?.building?.heatingType?.length
        ? [`Heating Details: ${listing?.building?.heatingType?.toString()}`]
        : null, // Shown in the UI
      homeOwnersAssociation: null,
      house: null,
      interiorFeatures: null,
      laundry: listing?.realCharacteristics?.communityFeatures || null, // Shown in the UI
      livingRoom: null,
      listing: [
        `Listing Price: ${listing?.listingInformation?.price}`,
        `Status: Active`,
      ],
      location: [
        `Address: ${
          listing?.address?.streetNumber
            ? `${listing?.address?.streetNumber}`
            : ''
        }${
          listing?.address?.streetName ? ` ${listing?.address?.streetName}` : ''
        }${
          listing?.address?.streetSuffix
            ? ` ${listing?.address?.streetSuffix}`
            : ''
        }, ${listing?.address.city}, ${listing?.address.province} ${
          listing?.address.postalCode
        }`,
        `Unit: ${listing?.address?.unitNumber}`,
        `City: ${listing?.address?.city}`,
        `State: ${listing?.address?.province}`,
        `Zip: ${listing?.address?.postalCode}`,
        `County: ${listing?.listingInformation?.countyID}`,
        `Subdivision: ${listing?.address?.subdivision}`,
      ],
      lot: [],
      parking: listing?.parkingDetails?.parkingSpaceTotal
        ? [
            `Total Parking Spaces: ${listing?.parkingDetails?.parkingSpaceTotal}`,
          ]
        : [], // Shown in the UI
      property: null,
      roof: listing?.building?.roofStyle || [],
      schools: [
        'Elementary: Call Listing Agent',
        'Middle/Junior High: Call Listing Agent',
      ],
      utilities:
        listing?.utilitiesAvailable?.map(
          (ele) => `${ele.type}: ${ele.description}`
        ) || [],
      securitySystem: listing?.building?.fireProtection || [],
      spa: listing?.realCharacteristics?.poolFeatures || [],
    },
  },
});

export const isOldAgentWebsiteResponse = (
  agentWebsite: RealApiAgentWebsiteResponse
): boolean => {
  return isNumber(agentWebsite?.agent?.id);
};

export const getRealProperties = async (
  pageNumber: number,
  stateSymbol?: string,
  pageSize = DEFAULT_PROPERTIES_ITEM_SIZE
): Promise<IProperty[] | void> => {
  if (stateSymbol) {
    const { data } = await new WandererControllerApi(
      getWandererConfiguration()
    ).getRealListingsForState(
      (pageNumber + 1).toString(),
      pageSize.toString(),
      stateSymbol
    );

    return data.listings?.map((listing) =>
      getIPropertyFromAbbreviatedListing(listing, data.legalese!)
    );
  }

  const { data } = await new WandererControllerApi(
    getWandererConfiguration()
  ).getRealListings((pageNumber + 1).toString(), pageSize.toString());

  return data.listings?.map((listing) =>
    getIPropertyFromAbbreviatedListing(listing, data.legalese!)
  );
};

export const getIPropertyFromAbbreviatedListing = (
  property: AbbreviatedListing,
  disclaimers: LegaleseElement[]
): IProperty => ({
  id: property.id,
  beds: property.beds!,
  baths: property.baths?.total || 0,
  disclaimer: disclaimers?.map((disclaimer) => ({
    image_url: disclaimer.asset?.url || null,
    body: disclaimer.text,
  })) as unknown as DisclaimerType,
  address1: property.address?.deliveryLine,
  address2: `${property.address?.city}, ${property.address?.state}, ${property.address?.zip}`,
  city: property.address?.city,
  state: property.address?.state,
  zip: property.address?.zip,
  county: '',
  gps_2: [property.coordinates!.latitude!, property.coordinates!.longitude!],
  latitude: property.coordinates?.latitude,
  longitude: property.coordinates?.longitude,
  mls: property.market,
  mls_id: property.market,
  mlsnum: property.id,
  list_price: property.listPrice,
  price_short: property.listPrice,
  property_type: property.propertyType,
  listing_agent_id: `${property.agentInfo?.slug}`,
  listing_agent_name: `${property.agentInfo?.firstName} ${property.agentInfo?.lastName}`,
  agent_website_url: `/${property.agentInfo?.slug}`,
  real_agent: property.agentInfo
    ? ({
        firstname: property.agentInfo.firstName,
        lastname: property.agentInfo.lastName,
        rect_profile_image: property.agentInfo.avatar
          ? `${env.NEXT_PUBLIC_YENTA_IMAGE_BASE_URL}/${property.agentInfo.avatar}`
          : null,
        slug: property.agentInfo.slug,
      } as any)
    : null,

  photos: (property.images || []).map((image, index) => ({
    index,
    large: image,
    medium: image,
    small: image,
    thumbnail: image,
  })),
  attributionContact: property?.attributionContact,
});

export const getBooleanValue = (
  val: string | string[] | undefined
): boolean | undefined =>
  typeof val !== 'undefined' ? val === 'true' : undefined;

export const getFilterFromQuery = (
  query: ParsedUrlQuery
): PropertiesFilterType =>
  getOnlyAppliedFilters({
    ...query,
    type: query.type ?? 'buy',
    page_number: getNumberValue(query.page_number),
    is_active: getBooleanValue(query.is_active),
    is_rental: getBooleanValue(query.is_rental),
    is_house: getBooleanValue(query.is_house),
    is_townhouse: getBooleanValue(query.is_townhouse),
    is_condo: getBooleanValue(query.is_condo),
    is_multifamily: getBooleanValue(query.is_multifamily),
    is_land: getBooleanValue(query.is_land),
    has_back_yard: getBooleanValue(query.has_back_yard),
    has_fireplace: getBooleanValue(query.has_fireplace),
    has_golf: getBooleanValue(query.has_golf),
    has_patio_deck: getBooleanValue(query.has_patio_deck),
    has_spa_hot_tub: getBooleanValue(query.has_spa_hot_tub),
    has_porch: getBooleanValue(query.has_porch),
    has_pool_private: getBooleanValue(query.has_pool_private),
    has_pool_area: getBooleanValue(query.has_pool_area),
    is_price_reduction: getBooleanValue(query.is_price_reduction),
    has_photos: getBooleanValue(query.has_photos),
    is_foreclosure: getBooleanValue(query.is_foreclosure),
    is_new_construction: getBooleanValue(query.is_new_construction),
    radius: getNumberValue(query.radius),
    is_vacant_land: getBooleanValue(query.is_vacant_land),
    is_multi_family: getBooleanValue(query.is_multi_family),
    is_single_family: getBooleanValue(query.is_single_family),
    is_retail: getBooleanValue(query.is_retail),
    is_office: getBooleanValue(query.is_office),
    is_hospitality: getBooleanValue(query.is_hospitality),
    is_business: getBooleanValue(query.is_business),
    is_agriculture: getBooleanValue(query.is_agriculture),
    is_parking: getBooleanValue(query.is_parking),
    is_recreational: getBooleanValue(query.is_recreational),
    is_industrial: getBooleanValue(query.is_industrial),
    has_garage: getBooleanValue(query.has_garage),
    has_basement: getBooleanValue(query.has_basement),
    has_air_conditioning: getBooleanValue(query.has_air_conditioning),
    has_waterfront: getBooleanValue(query.has_waterfront),
    has_washer_dryer_hookup: getBooleanValue(query.has_washer_dryer_hookup),
    has_pets_allowed: getBooleanValue(query.has_pets_allowed),
    has_tennis_court: getBooleanValue(query.has_tennis_court),
  } as PropertiesFilterType);

export const getPropertyDetail = async (
  mlsId: string,
  mlsNumber: string,
  agentId?: string
): Promise<IProperty[]> => {
  let response;
  try {
    response = await new WandererControllerApi(
      getWandererConfiguration()
    ).getListingDetail(mlsId, mlsNumber, agentId);
  } catch (e) {
    ErrorService.notifyIgnoreNotFound(
      'Unable to fetch property details from wanderer',
      e,
      {
        mls: { mlsId, mlsNumber },
        agent: { id: agentId },
      }
    );

    return;
  }

  return getPropertyObject(response.data);
};

export const getPropertyObject = (
  property: ListingDetailResponse
): IProperty[] => {
  const iProperty: IProperty = {
    id: property.fullListing.id,
    beds: property.fullListing.beds,
    baths: property.fullListing.baths?.total || 0,
    disclaimer: property.legalese?.map((disclaimer) => ({
      image_url: disclaimer.asset?.url || null,
      body: disclaimer.text,
    })) as unknown as DisclaimerType,
    address1: property.fullListing.address.deliveryLine,
    address2: `${property.fullListing.address.city}, ${property.fullListing.address.state}, ${property.fullListing.address.zip}`,
    city: property.fullListing.address.city,
    state: property.fullListing.address.state,
    zip: property.fullListing.address.zip,
    gps_2: [
      property.fullListing.coordinates.latitude,
      property.fullListing.coordinates.longitude,
    ],
    latitude: property.fullListing.coordinates.latitude,
    longitude: property.fullListing.coordinates.longitude,
    mls: property.fullListing.market,
    mls_id: property.fullListing.market,
    mlsnum: property.fullListing.id,
    list_price: property.fullListing.listPrice,
    price_short: property.fullListing.listPrice,
    sq_ft: property.fullListing.size,
    status: property.fullListing.status,
    listing_office_name: property.fullListing.listingOffice?.name || null,
    remarks: property.fullListing.description,
    style: property.fullListing.style,
    new_construction: property.fullListing.newConstruction ? 'Yes' : 'No',
    floors: property.fullListing.floors,
    listing_agent_id: property.fullListing.listingAgent?.id || null,

    photos: property.fullListing.images.map((image, index) => ({
      index,
      large: image,
      medium: image,
      small: image,
      thumbnail: image,
    })),

    fence_info: (property.fullListing.features.fence || []).join(),
    security_system: (
      property.fullListing.features.securitySystem || []
    ).join(),
    interior_spa: (property.fullListing.features.securitySystem || []).join(),
  };

  (property.fullListing.features.laundry || []).forEach((laundary, index) => {
    iProperty[`laundary_${index + 1}`] = laundary;
  });

  (property.fullListing.features.bedrooms || []).forEach((bed, index) => {
    iProperty[`beds_${index + 1}`] = bed;
  });

  (property.fullListing.features.bathrooms || []).forEach((bath, index) => {
    iProperty[`baths_${index + 1}`] = bath;
  });

  (property.fullListing.features.house || []).forEach((house, index) => {
    iProperty[`extra_house_info_${index + 1}`] = house;
  });

  (property.fullListing.features.location || []).forEach((location, index) => {
    iProperty[`location_info_${index + 1}`] = location;
  });

  (property.fullListing.features.heating || []).forEach((heating, index) => {
    iProperty[`heating_info_${index + 1}`] = heating;
  });

  (property.fullListing.features.interiorFeatures || []).forEach(
    (interior, index) => {
      iProperty[`interior_additional_${index + 1}`] = interior;
    }
  );

  (property.fullListing.features.appliancesAndEquipment || []).forEach(
    (appliance, index) => {
      iProperty[`appliances_info_${index + 1}`] = appliance;
    }
  );

  (property.fullListing.features.homeOwnersAssociation || []).forEach(
    (homeOwner, index) => {
      iProperty[`home_owner_${index + 1}`] = homeOwner;
    }
  );

  (property.fullListing.features.property || []).forEach((property, index) => {
    iProperty[`extra_property_info_${index + 1}`] = property;
  });

  (property.fullListing.features.parking || []).forEach((parking, index) => {
    iProperty[`parking_info_${index + 1}`] = parking;
  });

  (property.fullListing.features.amenities || []).forEach(
    (amenities, index) => {
      iProperty[`amenities_info_${index + 1}`] = amenities;
    }
  );

  (property.fullListing.features.cooling || []).forEach((cooling, index) => {
    iProperty[`colling_info_${index + 1}`] = cooling;
  });

  (property.fullListing.features.exteriorFeatures || []).forEach(
    (exterior, index) => {
      iProperty[`exterior_info_${index + 1}`] = exterior;
    }
  );

  (property.fullListing.features.fireplaces || []).forEach(
    (fireplace, index) => {
      iProperty[`fireplace_info_${index + 1}`] = fireplace;
    }
  );

  (property.fullListing.features.utilities || []).forEach((utility, index) => {
    iProperty[`water_${index + 1}`] = utility;
  });

  (property.fullListing.features.schools || []).forEach((school, index) => {
    iProperty[`school_info_${index + 1}`] = school;
  });

  (property.fullListing.features.roof || []).forEach((roof, index) => {
    iProperty[`roof_info_${index + 1}`] = roof;
  });

  (property.fullListing.features.lot || []).forEach((lot, index) => {
    iProperty[`lot_info_${index + 1}`] = lot;
  });

  (property.fullListing.features.listing || []).forEach((listing, index) => {
    iProperty[`extra_listing_info_${index + 1}`] = listing;
  });

  (property.fullListing.features.livingRoom || []).forEach(
    (livingRoom, index) => {
      iProperty[`living_room_${index + 1}`] = livingRoom;
    }
  );

  (property.fullListing.features.garage || []).forEach((garage, index) => {
    iProperty[`garage_info_${index + 1}`] = garage;
  });

  iProperty['listing_date'] = property.fullListing.listingDate;

  return [iProperty];
};

export const isOldAgentWebsiteRespose = (
  agentWebsite: RealApiAgentWebsiteResponse
): boolean => {
  return isNumber(agentWebsite?.agent?.id);
};

export const isPropertiesFilterAPICallable = (query: ParsedUrlQuery): boolean =>
  !isNil(query.latitude) && !isNil(query.longitude);

export const getPropertyTypeOptions = (
  filter: PropertiesFilterType,
  updateFilter: (key: keyof PropertiesFilterType, value: any) => void
): CheckboxInputProps[] => {
  const isCASite = isCASiteOnly();
  if (!isCASite) {
    const options = [
      {
        label: 'House',
        onChange: (value) => updateFilter('is_house', value),
        value: !!filter.is_house,
      },
      {
        label: 'Townhouse',
        onChange: (value) => updateFilter('is_townhouse', value),
        value: !!filter.is_townhouse,
      },
      {
        label: 'Condo',
        onChange: (value) => updateFilter('is_condo', value),
        value: !!filter.is_condo,
      },
    ];

    if (filter.type !== 'rent') {
      options.push(
        {
          label: 'Multifamily',
          onChange: (value) => updateFilter('is_multifamily', value),
          value: !!filter.is_multifamily,
        },
        {
          label: 'Land',
          onChange: (value) => updateFilter('is_land', value),
          value: !!filter.is_land,
        }
      );
    }

    return options;
  }

  const options = [
    {
      label: capitalizeEnum(FilterPropertyTypesEnum.VacantLand),
      onChange: (value) => updateFilter('is_vacant_land', value),
      value: !!filter.is_vacant_land,
    },
    {
      label: capitalizeEnum(FilterPropertyTypesEnum.MultiFamily),
      onChange: (value) => updateFilter('is_multi_family', value),
      value: !!filter.is_multi_family,
    },
    {
      label: capitalizeEnum(FilterPropertyTypesEnum.SingleFamily),
      onChange: (value) => updateFilter('is_single_family', value),
      value: !!filter.is_single_family,
    },
    {
      label: capitalizeEnum(FilterPropertyTypesEnum.Retail),
      onChange: (value) => updateFilter('is_retail', value),
      value: !!filter.is_retail,
    },
    {
      label: capitalizeEnum(FilterPropertyTypesEnum.Office),
      onChange: (value) => updateFilter('is_office', value),
      value: !!filter.is_office,
    },
    // To be added in the future
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Condo),
    //   onChange: (value) => updateFilter('is_condo', value),
    //   value: !!filter.is_condo,
    // },
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Hospitality),
    //   onChange: (value) => updateFilter('is_hospitality', value),
    //   value: !!filter.is_hospitality,
    // },
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Business),
    //   onChange: (value) => updateFilter('is_business', value),
    //   value: !!filter.is_business,
    // },
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Agriculture),
    //   onChange: (value) => updateFilter('is_agriculture', value),
    //   value: !!filter.is_agriculture,
    // },
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Parking),
    //   onChange: (value) => updateFilter('is_parking', value),
    //   value: !!filter.is_parking,
    // },
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Recreational),
    //   onChange: (value) => updateFilter('is_recreational', value),
    //   value: !!filter.is_recreational,
    // },
    // {
    //   label: capitalizeEnum(FilterPropertyTypesEnum.Industrial),
    //   onChange: (value) => updateFilter('is_industrial', value),
    //   value: !!filter.is_industrial,
    // },
  ];

  return options;
};

export const getAmenitiesOptions = (
  filter: PropertiesFilterType,
  updateFilter: (key: keyof PropertiesFilterType, value: any) => void
): CheckboxInputProps[] => {
  const isCASite = isCASiteOnly();
  if (!isCASite) {
    const options = [
      {
        label: 'Back Yard',
        onChange: (value) => updateFilter('has_back_yard', value),
        value: !!filter.has_back_yard,
      },
      {
        label: 'Fireplace',
        onChange: (value) => updateFilter('has_fireplace', value),
        value: !!filter.has_fireplace,
      },
      {
        label: 'Golf',
        onChange: (value) => updateFilter('has_golf', value),
        value: !!filter.has_golf,
      },
      {
        label: 'Patio Deck',
        onChange: (value) => updateFilter('has_patio_deck', value),
        value: !!filter.has_patio_deck,
      },
      {
        label: 'Pool Access',
        onChange: (value) => updateFilter('has_pool_area', value),
        value: !!filter.has_pool_area,
      },
      {
        label: 'Private Pool',
        onChange: (value) => updateFilter('has_pool_private', value),
        value: !!filter.has_pool_private,
      },
      {
        label: 'Porch',
        onChange: (value) => updateFilter('has_porch', value),
        value: !!filter.has_porch,
      },
      {
        label: 'Hot Tub',
        onChange: (value) => updateFilter('has_spa_hot_tub', value),
        value: !!filter.has_spa_hot_tub,
      },
    ];

    return options;
  }

  const options = [
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Pool),
      onChange: (value) => updateFilter('has_pool_area', value),
      value: !!filter.has_pool_area,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.HotTub),
      onChange: (value) => updateFilter('has_spa_hot_tub', value),
      value: !!filter.has_spa_hot_tub,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Garage),
      onChange: (value) => updateFilter('has_garage', value),
      value: !!filter.has_garage,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Basement),
      onChange: (value) => updateFilter('has_basement', value),
      value: !!filter.has_basement,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Fireplace),
      onChange: (value) => updateFilter('has_fireplace', value),
      value: !!filter.has_fireplace,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.AirConditioning),
      onChange: (value) => updateFilter('has_air_conditioning', value),
      value: !!filter.has_air_conditioning,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Waterfront),
      onChange: (value) => updateFilter('has_waterfront', value),
      value: !!filter.has_waterfront,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.WasherDryerHookup),
      onChange: (value) => updateFilter('has_washer_dryer_hookup', value),
      value: !!filter.has_washer_dryer_hookup,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.PetsAllowed),
      onChange: (value) => updateFilter('has_pets_allowed', value),
      value: !!filter.has_pets_allowed,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Backyard),
      onChange: (value) => updateFilter('has_back_yard', value),
      value: !!filter.has_back_yard,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Golf),
      onChange: (value) => updateFilter('has_golf', value),
      value: !!filter.has_golf,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.Porch),
      onChange: (value) => updateFilter('has_porch', value),
      value: !!filter.has_porch,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.PatioDeck),
      onChange: (value) => updateFilter('has_patio_deck', value),
      value: !!filter.has_patio_deck,
    },
    {
      label: capitalizeEnum(FilterAmenitiesEnum.TennisCourt),
      onChange: (value) => updateFilter('has_tennis_court', value),
      value: !!filter.has_tennis_court,
    },
  ];

  return options;
};

export const getTotalPropertiesPages = (totalProperties: number): number =>
  Math.min(
    Math.ceil(totalProperties / DEFAULT_PROPERTIES_ITEM_SIZE),
    MAX_PAGES_TO_SHOW
  );

export const getPropertyDetailPath = (
  property: PropertyType,
  agentSlug?: string,
  backUrl?: string
): UrlObject => {
  if (!agentSlug) {
    return {
      pathname: '/listings/[mlsId]/[mlsNumber]/[[...slug]]',
      query: {
        mlsId: property.mls,
        mlsNumber: property.mlsnum,
        slug: [
          `${property.city ? `${property.city}, ` : ''}${
            property.state ? `${property.state}, ` : ''
          }${property.county ? property.county : ''}`,
        ],
        backUrl: backUrl,
      },
    };
  }

  return {
    pathname: '/[agentSlug]/listings/[mlsId]/[mlsNumber]/[[...slug]]',
    query: {
      agentSlug: agentSlug,
      mlsId: property.mls,
      mlsNumber: property.mlsnum,
      slug: [
        `${property.city ? `${property.city}, ` : ''}${
          property.state ? `${property.state}, ` : ''
        }${property.county ? property.county : ''}`,
      ],
      backUrl: backUrl,
    },
  };
};

export const getPropertiesFilterFromStateCountry = async (
  state: string,
  country: string
): Promise<PropertiesFilterType> => {
  const geoCoder = new google.maps.Geocoder();
  const searchTerm = `${state}, ${country}`;

  try {
    const { results } = await geoCoder.geocode({
      address: searchTerm,
    });

    return getInitialFilter(results[0]);
  } catch (e) {
    ServerLogger.debug('Unable to find locations for that searchTerm', e);
    ErrorService.notify('Unable to find locations for that searchTerm', e, {
      agent: { searchTerm },
    });
  }
};

export const getShareOptionItems = (
  agent?: Agent,
  property?: ListingDetailResponse
): DropdownItem[] => {
  const shareListingUrl =
    typeof window !== 'undefined' &&
    `Check out this listing on OneReal! ${encodeURIComponent(
      window.location.href
    )} `;
  const items: DropdownItem[] = [
    {
      text: 'Copy Link',
      onClick: () => navigator.clipboard.writeText(window.location.href),
      icon: <Image src='/icons/copy.svg' width={14} height={14} alt='right' />,
    },
    {
      text: 'Email',
      onClick: () => {
        router.push(
          `mailto:?subject=${property.fullListing.address?.deliveryLine}, ${property.fullListing.address?.city}, ${property.fullListing.address?.state} - ${property.fullListing.address?.zip}&body=${shareListingUrl}`
        );
      },
      icon: <Image src='/icons/email.svg' width={14} height={14} alt='right' />,
    },
    {
      text: 'Text Message',
      onClick: () => {
        router.push(`sms:?&body=${shareListingUrl}`);
      },
      isHideForMobile: true,
      icon: (
        <Image
          src='/icons/textMessage.svg'
          width={14}
          height={14}
          alt='right'
        />
      ),
    },
  ];

  return items;
};

export const isRental = <T extends { listingType?: string }>(
  listing: T
): boolean =>
  listing.listingType === AbbreviatedListingListingTypeEnum.Rental ||
  listing.listingType === RealPropertyDetailsListingTypeEnum.Rent;

export const isAddressLineOneAvailable = (address: Address): boolean => {
  return !!address?.deliveryLine;
};

export const isAddressLineTwoAvailable = (address: Address): boolean => {
  return !!address?.city && !!address?.state && !!address?.zip;
};

export const getAddressLine1 = (address: Address): string => {
  if (!isAddressLineOneAvailable(address)) {
    return 'Address Not Available';
  }
  return `${address?.deliveryLine}`;
};

export const getAddressLine2 = (address: Address): string => {
  if (!isAddressLineOneAvailable(address)) {
    return 'Address Not Available';
  }
  return `${address?.city ? `${address?.city}, ` : ''}
  ${address?.state ? `${address?.state}, ` : ''}
  ${address?.zip ? `${address?.zip}` : ''}`;
};

export const calculateCostForProperty = (amount: number): string => {
  return displayAmount(
    { amount: amount, currency: MoneyValueCurrencyEnum.Usd },
    { hideCurrency: true }
  );
};

export const getPercentageBarAmount = (amount: number, total: number): number =>
  (amount / total) * 100 || 0;
