import { MoneyValue as MoneyValueApi } from '../openapi/bff';
import { EnumMap } from '../types';

export enum MoneyValueCurrencyEnum {
  Cad = 'CAD',
  Usd = 'USD',
}

export interface MoneyValue {
  amount?: number;
  currency?: MoneyValueCurrencyEnum;
}

const currencyToSymbol: EnumMap<MoneyValueCurrencyEnum, string> = {
  CAD: '$',
  USD: '$',
};

const numberWithCommas = (num: string): string => {
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export interface DisplayAmountOptions {
  hideZeroCents?: boolean;
  hideCurrency?: boolean;
  abs?: boolean;
}

export const displayAmount = (
  amount?: MoneyValue,
  options: DisplayAmountOptions = {
    hideZeroCents: false,
    hideCurrency: false,
    abs: false,
  }
): string => {
  if (amount?.amount === null || amount?.amount === undefined) {
    return 'N/A';
  }

  let formattedAmount = numberWithCommas(
    (options.abs ? Math.abs(amount.amount) : amount.amount).toFixed(2)
  );

  if (options.hideZeroCents) {
    formattedAmount = formattedAmount.replace(/\.00$/, '');
  }

  if (options.hideCurrency) {
    return `${currencyToSymbol[amount.currency!]}${formattedAmount}`;
  }

  return `${amount.currency} ${formattedAmount}`;
};

export const getMoneyValue = (
  moneyValue?: MoneyValueApi,
  minAmount?: number
): string => {
  switch (moneyValue?.currency) {
    case MoneyValueCurrencyEnum.Usd:
      return `$${displayShortAmount(moneyValue, minAmount)}`;
    case MoneyValueCurrencyEnum.Cad:
      return `$${displayShortAmount(moneyValue, minAmount)} ${
        moneyValue.currency
      }`;
    default:
      return '';
  }
};

export const displayShortAmount = (
  value: MoneyValueApi,
  minAmount?: number
): string => {
  let newValue = (value.amount || '').toString()!;

  if ((minAmount && value.amount >= minAmount) || value.amount! >= 10000) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    const suffixNum = Math.floor(('' + value.amount!).length / 3);
    let shortValue = '';

    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value.amount! / Math.pow(1000, suffixNum)
          : value.amount!
        ).toPrecision(precision)
      ).toString();

      const dotLessShortValue = (shortValue + '').replace(
        /[^a-zA-Z 0-9]+/g,
        ''
      );

      if (dotLessShortValue.length <= 2) {
        break;
      }
    }

    if (parseInt(shortValue) % 1 !== 0) {
      shortValue = parseInt(shortValue).toFixed(1);
    }

    newValue = shortValue + suffixes[suffixNum];
  }

  return newValue;
};
